import React, { useEffect, useMemo, useState } from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import {
  type ColumnDefinition,
  RowSkeleton,
  getComparator,
  stableSort
} from '@r40cap/ui'
import { type Algo, type AlgoOrder } from '@r40cap/algos-sdk'

import type { AlgoExecutionRow, AlgoExecutionPlusOrders } from './types'
import TableErrorBody from '../utils/TableErrorBody'
import ExecutionRow from './rows/ExecutionRow'
import { getExecutionRowsForAlgo } from './matching'

function getExecutionRows (orders: AlgoOrder[], algoMap?: Map<string, Algo>): AlgoExecutionPlusOrders[] {
  if (algoMap === undefined) {
    return []
  }
  const uniqueAlgos = new Set(orders.map((order) => order.algo))
  const rows: AlgoExecutionPlusOrders[] = []
  for (const algo of Array.from(uniqueAlgos.values())) {
    const algoOrders = orders.filter((order) => order.algo === algo)
    rows.push(...getExecutionRowsForAlgo(algoOrders, algoMap))
  }
  return rows
}

function AlgoExecutionsTableBody (props: {
  orders: AlgoOrder[]
  isLoading: boolean
  columns: Array<ColumnDefinition<AlgoExecutionRow, any>>
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
  openedExecs: readonly string[]
  toggleExec: (execId: string) => void
  algoMap: Map<string, Algo>
}): React.JSX.Element {
  const {
    orders,
    isLoading,
    columns,
    isError,
    error,
    openedExecs,
    toggleExec,
    algoMap
  } = props
  const [executionRows, setExecutionRows] = useState<AlgoExecutionPlusOrders[]>(getExecutionRows(orders, algoMap))

  const visibleExecs = useMemo(
    () => {
      return stableSort(executionRows, (a, b) => {
        return getComparator('desc', 'time')({ time: a.row.time }, { time: b.row.time })
      })
    },
    [executionRows]
  )

  useEffect(() => {
    setExecutionRows(getExecutionRows(orders, algoMap))
  }, [orders, algoMap])

  if (isError) {
    return (
      <TableErrorBody
        colsToSpan={columns.length + 1}
        error={error}
      />
    )
  } else if (isLoading) {
    const rows = []
    for (let i = 0; i < 5; i++) {
      rows.push(
        <RowSkeleton
          usedKey={i}
          columns={columns}
          frontBuffer={{
            key: 'toggle',
            alignment: 'center',
            variant: 'rectangular'
          }}
          key={i}
        />
      )
    }
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  } else if (orders.length === 0) {
    return (
      <TableBody>
        <TableRow sx={{ height: '100%' }}>
          <TableCell
            colSpan={columns.length}
            align='center'
          >
            <Typography sx={{ fontSize: 20 }}>No Data</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    )
  } else {
    const rows = visibleExecs.map((exec, i) => {
      return (
        <ExecutionRow
          key={i}
          execution={exec.row}
          containedOrders={exec.orders}
          columns={columns}
          isOpen={openedExecs.includes(exec.execId)}
          toggleOpen={() => { toggleExec(exec.execId) }}
        />
      )
    })
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  }
}

export default AlgoExecutionsTableBody
