import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { SerializedError } from '@reduxjs/toolkit'

export interface ApiError {
  data: string
  originalStatus: number
}

export function isApiError (error: any): error is ApiError {
  return error !== undefined && error.data !== undefined && error.originalStatus !== undefined
}

const isFetchBaseQueryError = (
  error: unknown
): error is FetchBaseQueryError => {
  return typeof error === 'object' && error !== null && 'status' in error
}

const isSerializedError = (
  error: unknown
): error is SerializedError => {
  return typeof error === 'object' && error !== null && 'message' in error
}

export function getErrorMessage (error: FetchBaseQueryError | SerializedError | undefined): string {
  let errorString: string = 'Unknown'
  console.error(error)
  if (isFetchBaseQueryError(error)) {
    if ('originalStatus' in error) {
      if (error.originalStatus === 500) {
        errorString = 'Unhandled Error in Server. Rety request, and contact Fink if the error persists.'
      } else if (error.originalStatus === 400) {
        errorString = error.data
      } else if (error.originalStatus === 401) {
        errorString = 'Unauthorized. Please login again.'
      } else if (error.originalStatus === 403) {
        errorString = 'You do not have permission to access this resource.'
      } else {
        errorString = `Unrecongnized Error Code: ${error.originalStatus}`
      }
    } else if (
      'data' in error &&
      typeof error.data === 'object' &&
      error.data !== null &&
      'error' in error.data &&
      typeof error.data.error === 'string'
    ) {
      errorString = error.data.error
    } else {
      errorString = `${error.status}`
    }
  } else if (isSerializedError(error)) {
    const errorMessage = error.message ?? 'Unknown'
    errorString = `${errorMessage}. Contact Fink to fix.`
  }
  return errorString
}
