import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Stack
} from '@mui/material'
import { Modal } from '@r40cap/ui'

import AlgoBotterTable from './AlgoBlotterTable'
import AlgosControlsPanel from './AlgosControlsPanel'

function AlgoBlotterModal (): React.JSX.Element {
  const { algoId } = useParams()
  const navigate = useNavigate()
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)
  const [isRawBlotterView, setIsRawBlotterView] = useState<boolean>(false)

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/algos/positions')
    }
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box sx={{
        width: '90vw',
        height: '90vh',
        justifyItems: 'center'
      }}
      >
        <Stack
          spacing={1}
          direction={'column'}
          sx={{
            padding: '40px',
            height: 'calc(100% - 80px)'
          }}
        >
          <Box sx={{ height: '7%' }}>
            <AlgosControlsPanel
              refreshFunction={() => { setRefreshSignal(!refreshSignal) }}
              isRawBlotterView={isRawBlotterView}
              setIsRawBlotterView={setIsRawBlotterView}
              includeViewSelection={false}
            />
          </Box>
          <Box sx={{ height: '93%' }}>
            <AlgoBotterTable
              refreshSignal={refreshSignal}
              isRawBlotterView={isRawBlotterView}
              algoId={algoId}
            />
          </Box>
        </Stack>
      </Box>
    </Modal>
  )
}

export default AlgoBlotterModal
