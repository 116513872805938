import React, { useEffect, useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material'
import {
  type ColumnDefinition,
  HeaderCell
} from '@r40cap/ui'
import { type Algo, algosApi } from '@r40cap/algos-sdk'

import type { AlgoExecutionRow, AlgoOrderRow } from './types'
import { algoExecutionColumns, algoExecutionColumnsNoAlgo, algoOrderColumns, algoOrderColumnsNoAlgo } from './constants'
import AlgoExecutionsTableBody from './AlgoExecutionsTableBody'
import AlgoOrdersTableBody from './AlgoOrdersTableBody'

function AlgoBotterTableHeader<T> (props: {
  columns: Array<ColumnDefinition<T, any>>
  includeBuffer: boolean
}): React.JSX.Element {
  const { palette } = useTheme()
  const { columns, includeBuffer } = props

  return (
    <TableHead>
      <TableRow>
        {includeBuffer && <TableCell sx={{ width: '5%', backgroundColor: palette.background.default }} />}
        {columns.map((column, idx) => (
          <HeaderCell<T, any>
            column={column}
            isActiveSort={false}
            order={'desc'}
            sortHandler={() => {}}
            key={idx}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
            dense
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

function AlgoBotterTable (props: {
  refreshSignal: boolean
  isRawBlotterView: boolean
  algoId?: string
}): React.JSX.Element {
  dayjs.extend(utc)
  const {
    refreshSignal,
    isRawBlotterView,
    algoId
  } = props
  const { palette } = useTheme()
  const [time, setTime] = useState<Dayjs>(dayjs().utc()) // eslint-disable-line
  const [algosMap, setAlgosMap] = useState<Map<string, Algo>>(new Map())
  const [openedExecs, setOpenedExecs] = useState<readonly string[]>([])

  const {
    data: algoOrdersData,
    isFetching: algoOrdersIsFetching,
    refetch: algoOrdersRefetch,
    isError: algoOrdersIsError,
    error: algoOrdersError
  } = algosApi.useGetAlgoOrdersQuery({
    start: time.subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[000Z]'),
    end: time.format('YYYY-MM-DDTHH:mm:ss.SSS[000Z]'),
    algoId
  })
  const { data: algosData } = algosApi.useGetAlgosQuery({})

  useEffect(() => {
    if (algosData !== null && algosData !== undefined) {
      setAlgosMap(new Map(algosData.data.map((val) => [val.algoId, val])))
    }
    setOpenedExecs([])
  }, [algosData])

  useEffect(() => {
    algoOrdersRefetch()
      .catch((error) => { console.error(error) })
  }, [refreshSignal])

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        height: '100%',
        borderRadius: '5px'
      }}
    >
      <Table stickyHeader sx={{ height: '100%' }}>
        {
          isRawBlotterView
            ? <AlgoBotterTableHeader<AlgoOrderRow>
              columns={algoId === undefined ? algoOrderColumns : algoOrderColumnsNoAlgo}
              includeBuffer={!isRawBlotterView}
            />
            : <AlgoBotterTableHeader<AlgoExecutionRow>
              columns={algoId === undefined ? algoExecutionColumns : algoExecutionColumnsNoAlgo}
              includeBuffer={!isRawBlotterView}
            />
        }
        {
          isRawBlotterView
            ? <AlgoOrdersTableBody
              isLoading={algoOrdersIsFetching}
              orders={algoOrdersData?.data ?? []}
              columns={algoId === undefined ? algoOrderColumns : algoOrderColumnsNoAlgo}
              isError={algoOrdersIsError}
              error={algoOrdersError}
            />
            : <AlgoExecutionsTableBody
              isLoading={algoOrdersIsFetching}
              orders={algoOrdersData?.data ?? []}
              columns={algoId === undefined ? algoExecutionColumns : algoExecutionColumnsNoAlgo}
              isError={algoOrdersIsError}
              error={algoOrdersError}
              openedExecs={openedExecs}
              toggleExec={(execId) => {
                if (openedExecs.includes(execId)) {
                  setOpenedExecs(openedExecs.filter((val) => val !== execId))
                } else {
                  setOpenedExecs([...openedExecs, execId])
                }
              }}
              algoMap={algosMap}
            />
        }
      </Table>
    </TableContainer>
  )
}

export default AlgoBotterTable
