import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import type { Algo, AlgoOrder } from '@r40cap/algos-sdk'

import type { AlgoExecutionPlusOrders } from './types'
import { getDisplayTime } from './utils'

dayjs.extend(utc)

const CME_MONTH_CODES = ['F', 'G', 'H', 'J', 'K', 'M', 'N', 'Q', 'U', 'V', 'X', 'Z']
const CME_YEARS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']

function isCmeFuture (
  baseFx: string,
  instrument: string
): boolean {
  if (baseFx === 'ETH') {
    return (
      instrument.length === 5 &&
        instrument.startsWith('ETH') &&
          CME_MONTH_CODES.includes(instrument[3]) &&
            CME_YEARS.includes(instrument[4])
    )
  }
  if (baseFx === 'BTC') {
    return (
      instrument.length === 5 &&
        instrument.startsWith('BTC') &&
          CME_MONTH_CODES.includes(instrument[3]) &&
            CME_YEARS.includes(instrument[4])
    )
  }
  return false
}

const ALLOWED_MS = 500
const ALLOWED_SIZE_ERROR = 0.0001

function getExecutionRowsForBaseCmeBasis (
  orders: AlgoOrder[],
  baseFx: string
): AlgoExecutionPlusOrders[] {
  if (baseFx !== 'ETH' && baseFx !== 'BTC') {
    return orders.map((order) => {
      return {
        execId: order.orderId,
        row: {
          time: order.time,
          displayTime: getDisplayTime(order.time),
          algo: order.algo,
          sizeDescription: '',
          spreadDescription: '',
          instrumentDescription: '',
          quotePrice: 0,
          latencyMs: 0,
          sizeSign: 0,
          quotePriceDecimals: 0,
          error: `Algo ${order.algo} not found`
        },
        orders: [order]
      }
    })
  }
  const cmeMult = baseFx === 'ETH' ? 50 : 5
  const cmeOrders = orders.filter((order) => isCmeFuture(baseFx, order.instrument))
  const nonCmeOrders = orders.filter((order) => !isCmeFuture(baseFx, order.instrument))
  const consumedNonCme = new Set<string>()
  const matches: AlgoExecutionPlusOrders[] = []
  for (const cmeOrder of cmeOrders) {
    let nettedQuantity = cmeOrder.netQty * cmeOrder.multiplier
    const cmeTime = dayjs.utc(cmeOrder.time)

    const potentialMatches = nonCmeOrders.filter((nonCmeOrder) => {
      if (consumedNonCme.has(nonCmeOrder.orderId)) {
        return false
      }
      if (Math.sign(nonCmeOrder.netQty) === Math.sign(cmeOrder.netQty)) {
        return false
      }
      if (Math.abs(nonCmeOrder.netQty * nonCmeOrder.multiplier) > (Math.abs(cmeOrder.netQty * cmeOrder.multiplier) + ALLOWED_SIZE_ERROR)) {
        return false
      }
      const nonCmeTime = dayjs.utc(nonCmeOrder.time)
      const msAfter = nonCmeTime.diff(cmeTime)
      if (msAfter < 0 || msAfter > ALLOWED_MS) {
        return false
      }
      return true
    })

    const timeSortedPotentialMatches = potentialMatches.sort((a, b) => {
      return dayjs.utc(a.time).diff(dayjs.utc(b.time))
    })

    const ordersForMatch: AlgoOrder[] = []
    for (const nonCmeOrder of timeSortedPotentialMatches) {
      if (Math.abs(nettedQuantity) > ALLOWED_SIZE_ERROR) {
        const newNettedQuantity = nettedQuantity + nonCmeOrder.netQty * nonCmeOrder.multiplier
        if (Math.abs(newNettedQuantity) < Math.abs(nettedQuantity)) {
          nettedQuantity = newNettedQuantity
          consumedNonCme.add(nonCmeOrder.orderId)
          ordersForMatch.push(nonCmeOrder)
        }
      }
    }
    if (Math.abs(nettedQuantity) > ALLOWED_SIZE_ERROR) {
      matches.push({
        execId: cmeOrder.orderId,
        row: {
          time: cmeOrder.time,
          displayTime: getDisplayTime(cmeOrder.time),
          algo: cmeOrder.algo,
          sizeDescription: '',
          spreadDescription: '',
          instrumentDescription: '',
          quotePrice: 0,
          latencyMs: 0,
          sizeSign: 0,
          quotePriceDecimals: 0,
          error: `Execution Leftover: ${nettedQuantity}`
        },
        orders: [cmeOrder, ...ordersForMatch]
      })
    } else {
      const sizeInCmeContracts = cmeOrder.netQty * -1
      const sizeInUly = sizeInCmeContracts * cmeMult
      const lastNonCmeFillTime = ordersForMatch[ordersForMatch.length - 1].time
      const latencyMs = dayjs.utc(lastNonCmeFillTime).diff(cmeTime)
      const totalValue = ordersForMatch.map((order) => order.price * order.netQty).reduce((a, b) => a + b, 0)
      const totalQty = ordersForMatch.map((order) => order.netQty).reduce((a, b) => a + b, 0)
      if (totalQty === 0 || totalValue === 0) {
        matches.push({
          execId: cmeOrder.orderId,
          row: {
            time: cmeOrder.time,
            displayTime: getDisplayTime(cmeOrder.time),
            algo: cmeOrder.algo,
            sizeDescription: '',
            spreadDescription: '',
            instrumentDescription: '',
            quotePrice: 0,
            latencyMs: 0,
            sizeSign: 0,
            quotePriceDecimals: 0,
            error: `Error computing quote price: ${totalValue} / ${totalQty}`
          },
          orders: [cmeOrder, ...ordersForMatch]
        })
      } else {
        const avgPrice = totalValue / totalQty
        const spread = cmeOrder.price - avgPrice
        const spreadBps = spread / avgPrice * 10000
        matches.push({
          execId: cmeOrder.orderId,
          row: {
            time: cmeOrder.time,
            displayTime: getDisplayTime(cmeOrder.time),
            algo: cmeOrder.algo,
            sizeDescription: `${sizeInCmeContracts} (${sizeInUly})`,
            spreadDescription: `${spread.toFixed(2)} (${spreadBps.toFixed(2)} bps)`,
            instrumentDescription: `${cmeOrder.instrument} - ${ordersForMatch[0].instrument}`,
            quotePrice: avgPrice,
            latencyMs,
            quotePriceDecimals: 2,
            sizeSign: Math.sign(sizeInCmeContracts)
          },
          orders: [cmeOrder, ...ordersForMatch]
        })
      }
    }
  }
  for (const nonCmeOrder of nonCmeOrders) {
    if (!consumedNonCme.has(nonCmeOrder.orderId)) {
      matches.push({
        execId: nonCmeOrder.orderId,
        row: {
          time: nonCmeOrder.time,
          displayTime: getDisplayTime(nonCmeOrder.time),
          algo: nonCmeOrder.algo,
          sizeDescription: '',
          spreadDescription: '',
          instrumentDescription: '',
          quotePrice: 0,
          latencyMs: 0,
          sizeSign: 0,
          quotePriceDecimals: 0,
          error: 'Failed to Match OKX Leg'
        },
        orders: [nonCmeOrder]
      })
    }
  }
  return matches
}

function getExecutionRowsForCmeBasis (
  ordersForAlgo: AlgoOrder[]
): AlgoExecutionPlusOrders[] {
  const uniqueBases = new Set(ordersForAlgo.map((order) => order.baseFx))
  const matches: AlgoExecutionPlusOrders[] = []
  for (const baseFx of Array.from(uniqueBases.values())) {
    const baseOrders = ordersForAlgo.filter((order) => order.baseFx === baseFx)
    matches.push(...getExecutionRowsForBaseCmeBasis(baseOrders, baseFx))
  }
  return matches
}

export function getExecutionRowsForAlgo (
  ordersForAlgo: AlgoOrder[],
  algoMap: Map<string, Algo>
): AlgoExecutionPlusOrders[] {
  if (ordersForAlgo.length === 0) {
    throw new Error('No orders for algo')
  }
  const algo = algoMap.get(ordersForAlgo[0].algo)
  if (algo === undefined) {
    return ordersForAlgo.map((order) => {
      return {
        execId: order.orderId,
        row: {
          time: order.time,
          displayTime: getDisplayTime(order.time),
          algo: order.algo,
          sizeDescription: '',
          spreadDescription: '',
          instrumentDescription: '',
          quotePrice: 0,
          latencyMs: 0,
          sizeSign: 0,
          quotePriceDecimals: 0,
          error: `Algo ${order.algo} not found`
        },
        orders: [order]
      }
    })
  }
  switch (algo.algoType) {
    case 'cme_basis':
      return getExecutionRowsForCmeBasis(ordersForAlgo)
    default:
      return ordersForAlgo.map((order) => {
        return {
          execId: order.orderId,
          row: {
            time: order.time,
            displayTime: getDisplayTime(order.time),
            algo: order.algo,
            sizeDescription: '',
            spreadDescription: '',
            instrumentDescription: '',
            quotePrice: 0,
            latencyMs: 0,
            sizeSign: 0,
            quotePriceDecimals: 0,
            error: `Algo Type ${algo.algoType} not supported`
          },
          orders: [order]
        }
      })
  }
}
