import React, { useState } from 'react'
import {
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { usePermissions as useAlgoPermissions } from '@r40cap/algos-sdk'

import NoAccess from './NoAccess'
import Page from './Page'
import { REDIRECT_VIEW } from '../components/algos/constants'
import AlgosControlsPanel from '../components/algos/AlgosControlsPanel'
import AlgoPositionsTable from '../components/algos/AlgoPositionsTable'
import AlgoBlotterTable from '../components/algos/AlgoBlotterTable'
import AlgoBlotterModal from '../components/algos/AlgoBlotterModal'

function Algos (): React.JSX.Element {
  const algoPermissions = useAlgoPermissions()
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)
  const [isRawBlotterView, setIsRawBlotterView] = useState<boolean>(false)

  return (
    algoPermissions.username !== undefined && algoPermissions.username !== null
      ? <Page>
          <Stack
            spacing={1}
            direction={'column'}
            width={'100%'}
            sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
          >
            <Box sx={{ height: '7%' }}>
              <AlgosControlsPanel
                refreshFunction={() => { setRefreshSignal(!refreshSignal) }}
                isRawBlotterView={isRawBlotterView}
                setIsRawBlotterView={setIsRawBlotterView}
                includeViewSelection
              />
            </Box>
            <Box sx={{ height: '93%' }}>
              <Routes>
                <Route path='' element={<Navigate to={REDIRECT_VIEW} replace />} />
                <Route path={'positions'} element={<AlgoPositionsTable refreshSignal={refreshSignal}/>}>
                  <Route path='blotter/:algoId' element={<AlgoBlotterModal />} />
                </Route>
                <Route path={'blotter'} element={<AlgoBlotterTable isRawBlotterView={isRawBlotterView} refreshSignal={refreshSignal}/>}/>
                <Route path='*' element={<Navigate to={REDIRECT_VIEW} replace />}/>
              </Routes>
            </Box>
          </Stack>
        </Page>
      : <NoAccess />
  )
}

export default Algos
